import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import {YMaps, Map, Placemark} from "react-yandex-maps"

import Layout from "../components/layout"
import Seo from "../components/seo"

const OfficePage = () => {
  return (
    <Layout activePage="office">
      <Seo title="Адвокатский кабинет Храпова Юрия Николаевича"/>
      <h2 className="main-title">
          Офис
      </h2>
      <p className="text-center"><span className="fw-bold">г. Москва, Таганский, площадь Рогожская застава, дом 2/1, стр.2
      четырёхэтажное здание – выходящее торцом на площадь</span></p>
      <p className="text-center"><span className="text-green fw-bold">метро Римская</span>/<span className="text-brown fw-bold">метро Площадь Ильича</span></p>
      <p className="text-center">выход № 2 – вправо 80 м.</p>
      <div  className="d-flex justify-content-center">
        <StaticImage
          src="../images/dom1.png"
          alt="about"
          placeholder="blurred"
          layout="fixed"
          height={200}
          />
      </div>
      <p className="text-center">&nbsp;</p>
      <p className="text-center">ж/д станция «Серп и Молот» - 25 м.</p>
      <p className="text-center">ж/д станция «Москва Товарная»/ МДЦ  Д-2  -  500 м.</p>
      <p className="text-center">*********</p>
      <p className="text-center">Остановки общественного транспорта: Римская, площадь Ильича, Библиотечная</p>
      <div  className="d-flex justify-content-center">
        <StaticImage
          src="../images/dom2.png"
          alt="about"
          placeholder="blurred"
          layout="fixed"
          height={200}
          />
      </div>
      <p className="text-center">&nbsp;</p>
      <p className="text-center fw-bold">Автостоянка  для  Клиентов</p>
      <p className="text-center fst-italic">заезд – шлагбаум с ул. Рабочая</p>
      <YMaps>
        <Map defaultState={{center:[55.745742,37.680647], zoom:12}} width={"100%"} height={"300px"}>
          <Placemark
                  defaultGeometry={[55.745742,37.680647]}
                  properties={{iconCaption:  "Адвокатский кабинет Храпова Ю.Н.", balloonContent:  "Адвокатский кабинет Храпова Ю.Н.", hintContent:  "Адвокатский кабинет Храпова Ю.Н." }}
                  modules = {['geoObject.addon.balloon', 'geoObject.addon.hint']}
                  />
        </Map>
      </YMaps>
    </Layout>
  )
}

export default OfficePage
